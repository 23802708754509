import React, { useContext, useState } from 'react';
import Button from 'components/control/button';
import { ModalContext, IModalContext } from 'contexts/modal';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_AREA_CARD_SAVE_SEARCH_BUTTON, GTM_CLICK_AREA_CARD_EDIT_SEARCH_BUTTON } from 'constants/events';
import styles from './style.module.scss';
import Image from 'next/image';
import { IUserContext, UserContext } from 'contexts/user';
import { useThemeContext } from 'contexts';
import generateSearchDescriptionFromFilters from 'utils/generate-search-description-from-filters';
import { useIsSmallMobile } from 'hooks/use-size-class';
import { buildClassName } from 'utils/build-class-name';
import { createSavedSearch } from 'data/saved-searches';
import { ThemeNames } from 'types/themes';

import type { ListingParams } from 'contexts/preferences/listing-params/types';
import type SavedSearch from 'data/saved-searches';

interface Props {
  listingParams: ListingParams;
  savedAttributes?: SavedSearch | undefined;
  isMobile?: boolean;
  isZoocasaTenant?: boolean;
}

const EditSearchCard = ({ savedAttributes, listingParams, isMobile, isZoocasaTenant }: Props) => {
  const { openModal } = useContext(ModalContext) as IModalContext;
  return (
    <div className={buildClassName(styles['card-wrapper'], isMobile && styles['mobile-card-wrapper'] )}>
      <div className={styles['check-wrapper']}>
        <Image
          src={isZoocasaTenant ? '/next/assets/images/alert-saved-zoo.svg' : '/next/assets/images/alert-saved-exp.svg'}
          alt='New area alert created'
          layout='fill'
          style={{ objectFit:'contain' }}
        />
      </div>
      <h2 className={styles.heading}>Search Alert Created</h2>
      <p>We&apos;ll send you daily updates for is search</p>
      <Button
        theme="secondary"
        label="Edit Alert"
        onClick={() => {
          savedAttributes && openModal('create-saved-search', { savedAttributes, query: listingParams } );
          trackEvent(GTM_CLICK_AREA_CARD_EDIT_SEARCH_BUTTON);
        }}
      />
    </div>
  );
};

export default function SaveSearchCard({ listingParams }: Props) {
  const { openModal } = useContext(ModalContext) as IModalContext;
  const [isSaved, setIsSaved] = useState(false);
  const [savedAttributes, setSavedAttributes] = useState<SavedSearch | undefined>(undefined);
  const [error, setError] = useState<string>();
  const { isAuthenticated } = useContext(UserContext) as IUserContext;
  const newDescription = listingParams?.filter ? generateSearchDescriptionFromFilters(listingParams.filter) : 'My Saved Search';
  const isMobile = useIsSmallMobile();
  const { themeName } = useThemeContext();
  const isZoocasaTenant = themeName === ThemeNames.ZOOCASA;

  const saveSearch = async (values: Record<string, unknown>) => {
    createSavedSearch({
      frequency: 1,
      description: values.description as string || 'My Saved Search',
      formParams: listingParams,
      source: themeName,
    })
      .then(({ data }) => {
        setSavedAttributes(data);
        setIsSaved(true);
        openModal('notification', { success: 'Your search has been saved to your account.' });
      })
      .catch(() => {
        setError('Unexpected Error! Your search was not saved.');
        console.log(error);
      });
  };

  const handleOnClick = () => {
    if (isAuthenticated) {
      saveSearch({ description: newDescription });
      trackEvent(GTM_CLICK_AREA_CARD_SAVE_SEARCH_BUTTON);
    } else {
      openModal('login-registration', { callback: () => {
        saveSearch({ description: newDescription });
        trackEvent(GTM_CLICK_AREA_CARD_EDIT_SEARCH_BUTTON);
      },
      });
    }
  };

  return (
    isSaved ?
      <EditSearchCard listingParams={listingParams} savedAttributes={savedAttributes} isMobile={isMobile} isZoocasaTenant={isZoocasaTenant} />
      : (
        <div className={buildClassName(styles['card-wrapper'], isMobile && styles['mobile-card-wrapper'] )}>
          <div className={styles['house-image-wrapper']}>
            <Image
              src={isZoocasaTenant ? '/next/assets/images/empty-saved-search-zoo.svg' : '/next/assets/images/empty-saved-search-exp.svg'}
              alt='Search within an area'
              layout='fill'
              style={{ objectFit:'contain' }}
            />
          </div>
          <h2 className={buildClassName(styles.heading, !isZoocasaTenant && styles['exp-theme'])}>Create a New Listing Alert</h2>
          <p>We&apos;ll keep you up to date on all the new listings for this search</p>
          <Button
            theme={isZoocasaTenant ? 'primary' : 'secondary'}
            label='Create Alert'
            onClick={handleOnClick}
          />
        </div>
      )
  );
}
